const DETAIL_PATHS = {
  ACT: "act",
  BSA: "bsa",
  CAT: "cat",
  DLQI: "dlqi",
  EASI: "easi",
  HIS4: "his4",
  HUR: "hurley",
  NAPSI: "napsi",
  NAPSIMM: "napsimm",
  PASI: "pasi",
  PGA: "pga",
  POEM: "poem",
  RIE_ASO: "riesgo-asociado",
  SCORAD: "scorad",
  TOPAS: "topas",
  UAS7: "uas7",
  UCT: "uct",
  WHOQOL_BREF: "whoql",
  ADCT: "adct",
  NRS: "nrs",
  CDLQI: "cdlqi",
  IDQOL:"idqol"
};

export default DETAIL_PATHS;
