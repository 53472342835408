<template>
  <div class="columns pt-5 px-5">
    <div class="column">
      <div class="block title question">
        <div class="survey-title">
          {{ surveyName }}
        </div>

        <div class="new-survey-container">
          <b-button
            class="new-survey-btn"
            icon-right="plus"
            rounded
            @click="newSurvey()"
            v-if="permissionValidation(permission.createSurvey)"
            >Nueva encuesta</b-button
          >
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Fecha de inicio">
            <b-datepicker
              v-model="startDate"
              ref="startDate"
              value="startDate"
              expanded
              placeholder="Fecha de inicio"
            >
            </b-datepicker>
            <b-button
              @click="$refs.startDate.toggle()"
              icon-left="calendar-today"
              type="is-info"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha final">
            <b-datepicker
              v-model="finalDate"
              :min-date="startDate"
              ref="endDate"
              expanded
              value="finalDate"
              placeholder="Fecha final"
            >
            </b-datepicker>
            <b-button
              @click="$refs.endDate.toggle()"
              icon-left="calendar-today"
              type="is-info"
            />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full refresh-container">
          <b-button
            type="is-danger"
            class="refresh-button"
            icon-right="calendar-search"
            @click="reloadSurveys"
          />
        </div>
      </div>
      <b-table
        :data="data"
        :bordered="false"
        :striped="false"
        :hoverable="true"
        :mobile-cards="true"
        :paginated="true"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :backend-pagination="true"
        :total="total"
        :sticky-header="true"
        :pagination-rounded="true"
        type="is-info"
        @page-change="makeSearch"
        class="mb-5"
      >
        <b-table-column field="patientId" label="Paciente" v-slot="props">
          {{ props.row.patientId }}
        </b-table-column>

        <b-table-column field="date" label="Fecha de encuesta" v-slot="props">
          {{ props.row.date | myDate }}
        </b-table-column>

        <b-table-column
          field="result"
          label="Resultado"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ props.row.result ? props.row.result : "No disponible" }}
        </b-table-column>

        <b-table-column
          field="appreciation"
          label="Apreciación"
          v-slot="props"
          :td-attrs="columnTdAttrs"
        >
          {{
            props.row.appreciation ? props.row.appreciation : "No disponible"
          }}
        </b-table-column>

        <b-table-column
          label="Acciones"
          v-slot="props"
          v-if="permissionValidation(permission.viewDetail)"
        >
          <b-button
            class="new-survey-btn"
            rounded
            @click="goToSurvey(props.row)"
            >Ir a encuesta</b-button
          >
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">Sin resultados</div>
        </template>

        <template #bottom-left>
          <b-select v-model="perPage">
            <option
              v-for="(item, index) in itemsPerPage"
              :key="index"
              :value="item"
              >{{ `${item} por página` }}</option
            >
          </b-select>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DETAIL_PATHS from "../../enum/detailPaths";

export default {
  props: {
    surveyName: {
      type: String,
      default: "",
    },
    surveyCode: {
      type: String,
      default: "",
    },
    permission: {
      type: Object,
      required: false,
      default: function() {
        return {
          createSurvey: null,
          viewDetail: null,
        };
      },
    },
  },
  data() {
    return {
      data: [],
      total: 0,
      isEmpty: false,
      perPage: 10,
      itemsPerPage: [10, 20, 30, 40, 50],
      currentPage: 1,
      startDate: null,
      finalDate: null,
    };
  },
  created() {
    this.$global.$on("patientDataDeleted", () => {
      this.getSurveys();
    });

    this.$global.$on("patientLoaded", () => {
      this.getSurveys();
    });

    this.getSurveys();
  },
  computed: {
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.getSurveys();
    },
  },
  methods: {
    goToSurvey(router = {}) {
      this.$router.push({
        name: `detail-${DETAIL_PATHS[router.surveyCode]}`,
        params: { surveyId: router.id },
      });

      this.$global.$emit("setPatientInput", router.patientId);
    },
    newSurvey() {
      if (this.patient) {
        this.$buefy.dialog.confirm({
          message: `Realizarás la encuesta ${this.surveyName} con los datos del paciente ${this.patient.name}.`,
          confirmText: "Aceptar",
          cancelText: "Cancelar",
          type: "is-info",
          onConfirm: () =>
            this.$router.push({ name: `new-${DETAIL_PATHS[this.surveyCode]}` }),
        });
      } else {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      }
    },
    reloadSurveys() {
      this.getSurveys();
      this.startDate = null;
      this.finalDate = null;
    },
    async getSurveys() {
      this.changeLoaderStatus(true);
      try {
        if (
          moment(this.finalDate).format("YYYY-MM-DD") <
          moment(this.startDate).format("YYYY-MM-DD")
        ) {
          this.showToast(
            "Las fecha final no debe ser mayor a la fecha de inicio.",
            "is-warning",
            3000
          );
          this.changeLoaderStatus(false);
          return;
        }

        if (this.patient)
          this.$global.$emit("setPatientInput", this.patient?.document);

        const response = await this.$surveyService.getSurveys(
          this.currentPage,
          this.perPage,
          this.surveyCode,
          this.startDate
            ? moment(this.startDate).format("YYYY-MM-DD 00:00:00")
            : null,
          this.finalDate
            ? moment(this.finalDate).format("YYYY-MM-DD 23:59:59")
            : null,
          this.patient ? this.patient.document : null
        );

        this.data = response.data[0];
        this.total = response.data[1];
        this.changeLoaderStatus(false);
      } catch (error) {
        console.log(error);
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    makeSearch(page) {
      this.currentPage = page;
      this.getSurveys();
    },
    columnTdAttrs(row, col) {
      if (col.field == "appreciation") {
        if (row.appreciation === null || row.appreciation === "") {
          return {
            class: "no-avaliable-field",
          };
        }
      } else if (col.field == "result") {
        if (row.result === null || row.result === "") {
          return {
            class: "no-avaliable-field",
          };
        }
      }
    },
    permissionValidation(permission) {
      return this.$ability.can(permission);
    },
  },
};
</script>

<style scoped>
.question {
  display: flex;
}

.survey-title {
  width: 50%;
  text-align: start;
}
.new-survey-container {
  width: 50%;

  text-align: end;
}

.refresh-container {
  text-align: right;
}
</style>
