<template>
  <div>
    <surveys-table
      surveyName="PGA"
      surveyCode="PGA"
      :permission="{
        createSurvey: permissions.CREATE_SURVEY_PGA,
        viewDetail: permissions.SEE_DETAIL_SURVEY_PGA,
      }"
    ></surveys-table>
  </div>
</template>

<script>
import SurveysTable from "../../../components/Forms/SurveysTable.vue";
import { permissions } from "../../../enum/applicationPermissions";

export default {
  components: {
    SurveysTable,
  },
  data() {
    return {
      permissions: permissions,
    };
  },
};
</script>

<style scoped></style>
